import React from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import * as style from '../assets/style/pages/post-page.module.scss';

const PostPage = ({location, data}) => {
  const post = data.post.edges.map(({node}) => node)[0];

  const crumbs = [
    {title: 'Strona główna', url: '/'},
    {title: 'Blog', url: `/blog`},
    {title: post.title.value, url: `/blog/${post.title.slug}`}
  ];

  return (
    <Layout>
      <Seo title={post.title.value}>
        <script type='application/ld+json'>
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(
                ({title, url}, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `
              )}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.post}>
        <div className='container'>
          <GatsbyImage image={getImage(post.image.value)} alt={post.title.value} />
          <div className={`${style.post__content} col-12 col-lg-10`}>
            <h1>{post.title.value}</h1>
            <div dangerouslySetInnerHTML={{__html: post.excerpt.value}}></div>
            <div dangerouslySetInnerHTML={{__html: post.content.value.replace(/\/storage\//gm, 'https://admin-expozytor.codemag.pl/storage/')}}></div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    post: allCockpitBlog(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          date: cockpitCreated
          title {
            value
            slug
          }
          image {
            value {
              childImageSharp {
                gatsbyImageData(
                  width: 1110
                  height: 500
                  quality: 100
                  formats: WEBP
                  backgroundColor: "#f2f2f2"
                  placeholder: BLURRED
                  transformOptions: {fit: COVER, cropFocus: CENTER}
                )
              }
            }
          }
          excerpt {
            value
          }
          content {
            value
          }
        }
      }
    }
  }
`;

export default PostPage;
